import GATSBY_COMPILED_MDX from "/opt/build/repo/content/blog/so-i-started-a-blog.mdx";
import * as React from "react";
import {graphql} from "gatsby";
import PostLayout from "../components/post-layout";
import * as postStyles from "../post.module.css";
import {ThemeProvider} from "../context/ThemeContext";
import {PageProvider} from "../context/PageContext";
import {MDXProvider} from "@mdx-js/react";
import TableOfContents from "../components/TableOfContents";
const BlogPostTemplate = ({data, location, children}) => {
  const post = data.mdx;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const shortCodes = {
    TOC: props => React.createElement(TableOfContents, Object.assign({}, props, {
      items: post.tableOfContents.items
    }))
  };
  return React.createElement(ThemeProvider, null, React.createElement(PageProvider, {
    value: post
  }, React.createElement(PostLayout, {
    location: location,
    headerTitle: siteTitle,
    title: post.frontmatter.title,
    description: post.frontmatter.description || post.excerpt,
    date: post.frontmatter.date,
    tags: post.frontmatter.tags
  }, React.createElement("div", {
    className: postStyles.content,
    id: "md-content",
    itemProp: "articleBody"
  }, React.createElement(MDXProvider, {
    components: shortCodes
  }, children)))));
};
BlogPostTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
      }
      tableOfContents
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
