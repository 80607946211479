/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    blockquote: "blockquote",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "I’ve been wanting to start a blog for a while now, and I finally got around to it. I’m not sure what I’ll be writing about, but I’m sure I’ll figure it out. I’m also not sure how often I’ll be writing, but I’m sure I’ll figure that out too."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "GitHub Copilot"), "\n"), "\n"), "\n", React.createElement(_components.p, null, "GitHub Copilot already said the important part. Well, almost."), "\n", React.createElement(_components.p, null, "I started playing around with the idea of starting a blog about a year ago but wasn’t really sure what I wanted to do. However, I recently came across this gatsby template by Sharad Raj: ", React.createElement(_components.a, {
    href: "https://github.com/sharadcodes/gatsby-theme-serial-programmer"
  }, "gatsby-theme-serial-programmer"), ", and thought it looked really nice and simple. So I spend a weekend tweaking it and here we are. There are still many things left to but it is already enough to start writing some posts."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
